import React, { Component } from "react";
import { donationTypePresets } from "../constants";
import ToggleButton from "./Form/ToggleButton";

class DonationType extends Component {
  donationTypeButtons = () => {
    return donationTypePresets.map((donationType) => {
      return (
        <ToggleButton
          value={donationType}
          onChange={this.props.donationTypeChange}
          label={donationType}
          key={donationType}
          donationType={this.props.donationType}
        />
      );
    });
  };

  render() {
    return (
      <div className='mb-3 mt-3'>
        <h3 className='mb-3 text-uppercase'>Donation Type</h3>
        <div className='donation-buttons'>{this.donationTypeButtons()}</div>
      </div>
    );
  }
}

export default DonationType;
