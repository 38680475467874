import { createRoot } from "react-dom/client";
import "./styles/main.css";
import App from "./App";
import LogRocket from "logrocket";

// Initialize LogRocket
LogRocket.init("zmctql/public-donation-app");

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
