import React from "react";

const Textarea = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const inputClassNme = () => {
    if (submitCount > 0) {
      if (errors[field.name]) {
        return "form-control is-invalid";
      }
      if (!errors[field.name]) {
        return "form-control is-valid";
      }
    }
    return "form-control";
  };
  return (
    <div className='form-group'>
      <label htmlFor={props.name}>{props.label}</label>
      <textarea
        className={inputClassNme()}
        {...field}
        {...props}
        id={props.name}
        // required={props.required || false}
        rows='3'
      />
      <div className='invalid-feedback'>{errors[field.name] || null}</div>
    </div>
  );
};

export default Textarea;
