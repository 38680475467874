import React from "react";

const Dropdown = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const inputClassNme = () => {
    if (touched[field.name]) {
      if (errors[field.name]) {
        return "form-control is-invalid";
      }
      if (!errors[field.name]) {
        return "form-control is-valid";
      }
    }
    return "form-control";
  };
  const selectOptions = props.options.map((option, index) => {
    return (
      <option key={option} value={props.valuePresets ? props.valuePresets[index] : option}>
        {option}
      </option>
    );
  });
  return (
    <>
      <label htmlFor={props.id}>{props.label}</label>
      <select
        className={inputClassNme()}
        {...field}
        options={props.options}
        label={props.label}
        disabled={props.disabled || false}
        // id={props.id}
        // value={props.placeholder}
        // onChange={this.props.onChange}
      >
        {selectOptions}
      </select>
      <div className='invalid-feedback'>{errors[field.name] || null}</div>
    </>
  );
};

export default Dropdown;
