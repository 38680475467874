import React from "react";
import commaNumber from "comma-number";

const ToggleButton = ({
  amount,
  value,
  label,
  otherFocus,
  donationType,
  onChange,
  donationTypeLabel,
}) => {
  const buttonClassNames = () => {
    if ((amount === value && otherFocus === false) || (donationType && donationType === value)) {
      return "btn btn-primary";
    }
    return "btn btn-secondary";
  };

  return (
    <button className={buttonClassNames()} value={value} onClick={onChange}>
      {!isNaN(label) ? `$ ${commaNumber(value)}` : label}
      {donationTypeLabel === "Monthly" ? "/mo" : ""}
    </button>
  );
};

export default ToggleButton;
