import { Component } from "react";

class Header extends Component {
  render() {
    return (
      <div className='mb-3 mt-3'>
        <h1 className='text-uppercase'>Support KPFA Today!</h1>
        <p>
          Please complete the form below to make your tax-deductible donation. Note that Monthly
          donations continue indefinitely until canceled. Please allow at least 6-8 weeks for
          donation gifts to be mailed to you.
        </p>
        <p>
          <b>
            <a href='https://billing.stripe.com/p/login/eVa5m70He0uZ97q8ww'>Login here</a>
          </b>{" "}
          to make changes to your current billing information or to cancel an ongoing payment.
        </p>
      </div>
    );
  }
}

export default Header;
