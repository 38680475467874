import React, { Component } from "react";

class ShowMore extends Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false, displayButton: true };
    this.heightRef = React.createRef();
  }

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  displayShowMoreLink = () => {
    const paragraphHeight = this.heightRef.current.clientHeight;
    const paragraphScrollHeight = this.heightRef.current.scrollHeight;
    // console.log("Paragraph heights", paragraphHeight, paragraphScrollHeight);
    if (paragraphHeight === paragraphScrollHeight) {
      this.setState({ displayButton: false });
    }
  };

  componentDidMount() {
    this.displayShowMoreLink();
  }

  render() {
    return (
      <div>
        <p
          className={this.state.showMore === true ? "card-text show" : "card-text"}
          ref={this.heightRef}
        >
          {this.props.description}
        </p>
        {this.state.displayButton === true ? (
          <button type='button' className='btn btn-link' onClick={this.toggleShowMore}>
            {this.state.showMore === false ? "show more" : "show less"}
          </button>
        ) : null}
      </div>
    );
  }
}

export default ShowMore;
