import PremiumsByCategory from "./PremiumsByCategory";

const PremiumsTabContent = (props) => {
  if (props.categories.length === 0) {
    return (
      <div className='d-flex justify-content-center'>
        <h3 className='text-uppercase'>
          <div
            className='spinner-border text-primary'
            style={{ width: "2rem", height: "2rem", marginRight: "0.5rem" }}
            role='status'
          >
            <span className='sr-only'>Loading Pledge Gifts...</span>
          </div>
          Loading Pledge Gifts...
        </h3>
      </div>
    );
  }
  if (props.categories.length > 0) {
    return (
      <li className='tab-pane fade show active'>
        <PremiumsByCategory
          activeTab={props.activeTab}
          categories={props.categories}
          premiums={props.premiums}
          addPremium={props.addPremium}
          donationType={props.donationType}
          cartIDs={props.cartIDs}
        />
      </li>
    );
  }
};

export default PremiumsTabContent;
