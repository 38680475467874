import React from "react";
import ToggleButton from "./Form/ToggleButton";
import "../styles/amount-selector.css";

const AmountSelector = ({
  amountChange,
  amount,
  otherFocus,
  otherAmount,
  donationType,
  amountPresets,
}) => {
  const amountButtons = (presets) => {
    return presets.map((presetAmount) => {
      return (
        <ToggleButton
          value={presetAmount}
          onChange={amountChange}
          label={presetAmount}
          key={presetAmount}
          amount={amount}
          otherFocus={otherFocus}
          donationTypeLabel={donationType}
        />
      );
    });
  };

  return (
    <div className='mb-3 mt-3'>
      <h3 className='mb-3 text-uppercase'>Donation Amount</h3>
      <div className='donation-buttons'>
        {amountButtons(amountPresets)}
        <div className='input-group mb-3'>
          <div className='input-group-prepend'>
            <button
              className={
                otherFocus === true || (amount === otherAmount && !amountPresets.includes(amount))
                  ? "btn btn-outline-primary active"
                  : "btn btn-outline-secondary"
              }
              type='button'
            >
              Other Amount
            </button>
          </div>
          <input
            type='text'
            className='form-control'
            id='other-amount'
            placeholder='Enter other amount'
            aria-label=''
            aria-describedby='basic-addon1'
            onChange={amountChange}
            onBlur={amountChange}
            onFocus={amountChange}
            value={
              otherFocus === true || (amount === otherAmount && !amountPresets.includes(amount))
                ? otherAmount
                : ""
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AmountSelector;
