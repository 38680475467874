import { Formik, Form, Field } from "formik";
import countries from "iso-3166-1-alpha-2";

import Checkbox from "./Form/Checkbox";
import Textarea from "./Form/Textarea";
import InputFlexible from "./Form/InputFlexible";
import DropdownFlexible from "./Form/DropdownFlexible";
import { states, statesAbbreviations } from "./Form/constants";
import supportFormSchema from "./Form/validationSchema";
import { donationTypes } from "../constants";

const ContactForm = ({
  updateStateFormData,
  amount,
  donationType,
  formData,
  incrementSubmissionCount,
  premiums,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          showShippingAddress: false,
          firstName: "",
          lastName: "",
          address1: "",
          address2: "",
          city: "",
          state: "CA",
          zip: "",
          country: "US",
          phone: "",
          email: "",
          firstNameShipping: "",
          lastNameShipping: "",
          address1Shipping: "",
          address2Shipping: "",
          cityShipping: "",
          stateShipping: "CA",
          zipShipping: "",
          countryShipping: "US",
          comment: "",
          readOnair: false,
          paperless: false,
          coverFees: false,
          donationMatch: false,
          acceptTos: "",
        }}
        validationSchema={supportFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (
            values !== formData ||
            amount !== this?.props?.amount ||
            donationType !== this?.props?.donationType ||
            premiums !== this?.props?.premiums
          ) {
            incrementSubmissionCount();
          }
          updateStateFormData(values);

          setSubmitting(false);
        }}
      >
        {({
          values,
          submitCount,
          setValues,
          handleBlur,
          handleChange,
          setFieldValue,
          errors,
          isSubmitting,
        }) => (
          <Form className='needs-validation'>
            <h3 className='mb-3 text-uppercase'>
              {values.showShippingAddress === false
                ? "Billing & Shipping Address"
                : "Billing Address"}
            </h3>
            <Field
              name='showShippingAddress'
              label='Shipping Address is different from billing address'
              component={Checkbox}
              checked={values.showShippingAddress}
              // onChange={e => {
              //   if (e.target.checked === false) {
              //     setFieldValue("firstNameShipping", "");
              //     setFieldValue("lastNameShipping", "");
              //     setFieldValue("address1Shipping", "");
              //     setFieldValue("address2Shipping", "");
              //     setFieldValue("cityShipping", "");
              //     setFieldValue("stateShipping", "");
              //     setFieldValue("zipShipping", "");
              //     setFieldValue("countryShipping", "");
              //   }
              //   handleChange(e);
              // }}
            />
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <Field
                  name='firstName'
                  label='First Name'
                  component={InputFlexible}
                  placeholder='Jane'
                />
              </div>
              <div className='form-group col-md-6'>
                <Field
                  name='lastName'
                  label='Last Name'
                  component={InputFlexible}
                  placeholder='Smith'
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <Field
                  name='address1'
                  label='Address'
                  component={InputFlexible}
                  placeholder='123 Main St.'
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <Field
                  name='address2'
                  label='Address (continued)'
                  component={InputFlexible}
                  placeholder='Apartment 161'
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <Field name='city' label='City' component={InputFlexible} placeholder='Berkeley' />
              </div>
              <div className='form-group col-md-6'>
                {values.country === "US" ? (
                  <Field
                    name='state'
                    label='State'
                    component={DropdownFlexible}
                    options={states}
                    valuePresets={statesAbbreviations}
                  />
                ) : (
                  <Field
                    name='state'
                    label='State or Provence'
                    component={InputFlexible}
                    placeholder='Ontario'
                  />
                )}
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                {values.country === "US" ? (
                  <Field
                    name='zip'
                    label='Zip'
                    component={InputFlexible}
                    placeholder='94710'
                    autoComplete='postal-code'
                    mask={(value) => {
                      const filteredValue = value.replace(/[^0-9.]+/g, "");
                      if (parseInt(filteredValue).toString().length <= 5) {
                        return [/\d/, /\d/, /\d/, /\d/, /\d/];
                      }
                      return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
                    }}
                    guide={false}
                    onChange={(e) => {
                      setFieldValue("zip", e.target.value.replace(/[^0-9.]+/g, ""));

                      // handleChange(e);
                    }}
                    value={values.zip}
                    // type="number"
                  />
                ) : (
                  <Field
                    name='zip'
                    label='Zip or Postal Code'
                    autoComplete='postal-code'
                    component={InputFlexible}
                    placeholder='94710'
                    // type="number"
                  />
                )}
              </div>
              <div className='form-group col-md-6'>
                <Field
                  name='country'
                  label='Country'
                  component={DropdownFlexible}
                  options={countries.getCountries()}
                  valuePresets={countries.getCodes()}
                  onChange={(e) => {
                    if (e.target.value !== "US") {
                      if (values.country === "US") {
                        setFieldValue("state", "");
                        setFieldValue("zip", "");
                      }
                    }
                    if (e.target.value === "US") {
                      if (values.country !== "US") {
                        setFieldValue("state", "CA");
                        setFieldValue("zip", "");
                      }
                    }
                    handleChange(e);
                  }}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                {values.country === "US" ? (
                  <Field
                    id='phone'
                    name='phone'
                    label='Phone'
                    component={InputFlexible}
                    placeholder='(510) 123-4567'
                    autoComplete='on'
                    mask={[
                      "(",
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={(e) => {
                      setFieldValue("phone", e.target.value.replace(/[^0-9.]+/g, ""));

                      // handleChange(e);
                    }}
                  />
                ) : (
                  <Field
                    id='phone'
                    name='phone'
                    label='Phone'
                    component={InputFlexible}
                    placeholder='15101234567'
                    onChange={(e) => {
                      setFieldValue("phone", e.target.value.replace(/[^0-9.]+/g, ""));

                      // handleChange(e);
                    }}
                  />
                )}
              </div>
              <div className='form-group col-md-6'>
                <Field
                  type='email'
                  name='email'
                  label='Email'
                  component={InputFlexible}
                  placeholder='name@domain.com'
                />
              </div>
            </div>

            {values.showShippingAddress === false ? null : (
              <>
                <h3 className='mb-3 mt-3 text-uppercase'>Shipping Address</h3>

                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <Field
                      name='firstNameShipping'
                      label='First Name'
                      component={InputFlexible}
                      placeholder='Jane'
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <Field
                      name='lastNameShipping'
                      label='Last Name'
                      component={InputFlexible}
                      placeholder='Smith'
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-12'>
                    <Field
                      name='address1Shipping'
                      label='Address'
                      component={InputFlexible}
                      placeholder='123 Main St.'
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-12'>
                    <Field
                      name='address2Shipping'
                      label='Address (continued)'
                      component={InputFlexible}
                      placeholder='Apartment 161'
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <Field
                      name='cityShipping'
                      label='City'
                      component={InputFlexible}
                      placeholder='Berkeley'
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    {values.countryShipping === "US" ? (
                      <Field
                        name='stateShipping'
                        label='State'
                        component={DropdownFlexible}
                        options={states}
                        valuePresets={statesAbbreviations}
                      />
                    ) : (
                      <Field
                        name='stateShipping'
                        label='State or Provence'
                        component={InputFlexible}
                        placeholder='Ontario'
                      />
                    )}
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    {values.countryShipping === "US" ? (
                      <Field
                        name='zipShipping'
                        label='Zip'
                        component={InputFlexible}
                        placeholder='94710'
                        // type="number"
                        mask={(value) => {
                          const filteredValue = value.replace(/[^0-9.]+/g, "");
                          if (parseInt(filteredValue).toString().length <= 5) {
                            return [/\d/, /\d/, /\d/, /\d/, /\d/];
                          }
                          return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
                        }}
                        guide={false}
                        onChange={(e) => {
                          setFieldValue("zipShipping", e.target.value.replace(/[^0-9.]+/g, ""));

                          // handleChange(e);
                        }}
                        value={values.zipShipping}
                      />
                    ) : (
                      <Field
                        name='zipShipping'
                        label='Zip or Postal Code'
                        component={InputFlexible}
                        placeholder='94710'
                        // type="number"
                      />
                    )}
                  </div>
                  <div className='form-group col-md-6'>
                    <Field
                      name='countryShipping'
                      label='Country'
                      component={DropdownFlexible}
                      options={countries.getCountries()}
                      valuePresets={countries.getCodes()}
                      // placeholder="US"

                      onChange={(e) => {
                        if (e.target.value !== "US") {
                          if (values.country === "US") {
                            setFieldValue("stateShipping", "");
                            setFieldValue("zipShipping", "");
                          }
                        }
                        if (e.target.value === "US") {
                          if (values.countryShipping !== "US") {
                            setFieldValue("stateShipping", "CA");
                            setFieldValue("zipShipping", "");
                          }
                        }
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <h3 className='mb-3 mt-4 text-uppercase'>Comments</h3>
            <Field
              component={Textarea}
              name='comment'
              label='Enter any comments you may have (to contact someone about a KPFA Membership or pledge gift visit our staff directory): '
            />
            <Field
              component={Checkbox}
              label='Check this box if you would like your first name mentioned on air, KPFA social media pages, or our newsletter.'
              name='readOnair'
              checked={values.readOnair}
            />
            <Field
              component={Checkbox}
              label='To help KPFA save resources, check this box to receive the end-of-year tax letter via email only.'
              name='paperless'
              checked={values.paperless}
            />
            {(Number(amount) >= 200 && donationType === donationTypes.oneTime) ||
            (Number(amount) >= 20 && donationType === donationTypes.monthly) ? (
              <Field
                component={Checkbox}
                label='Can we use this donation as a match?'
                name='donationMatch'
                checked={values.donationMatch}
              />
            ) : null}
            {/*
          <Field
            component={Checkbox}
            label="Check this box to cover credit card processing fees for your donation"
            name="coverFees"
            checked={values.coverFees}
            onChange={(e) => {
              handleChange(e);
              toggleCoverFees(e.target.checked);
            }}
          />
          */}
            <Field name='acceptTos' component={InputFlexible} placeholder='' hidden />
            <button
              type='submit'
              //disabled={isSubmitting || stripeDisabled || stripeError}
              disabled={isSubmitting}
              className='btn btn-primary btn-lg btn-block mb-5 mt-5'
            >
              Go to next step &#8594;
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
