import React, { Component } from "react";

import PageHeader from "./components/PageHeader";
import OtherWaysToSupport from "./components/OtherWaysToSupport";
import Header from "./components/Header";
import DonationType from "./components/DonationType";
import AmountSelector from "./components/AmountSelector";
import Premiums from "./components/Premiums";
import ContactForm from "./components/ContactForm";
import Summary from "./components/Summary";
import SubmitCart from "./components/SubmitCart";
import {
  amountPresetsMonthly,
  amountPresetsOneTime,
  donationTypes,
  donationLimits,
} from "./constants";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCarSide, faCircle, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faBtc } from "@fortawesome/free-brands-svg-icons";

library.add(faCarSide, faBtc, faCircle, faCreditCard);

// import Featured from "./components/Featured";
// import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 25,
      amountSelection: 25,
      otherAmount: "",
      otherFocus: false,
      oneTimeTotal: 0,
      recurringTotal: 0,
      minWarning: false,
      maxWarning: false,
      amountIncreaseWarning: false,
      donationType: donationTypes.oneTime,
      premiums: [],
      categories: [],
      cartIDs: [],
      cart: [],
      cartDetails: [],
      formData: {},
      canSubmit: false,
      showModal: false,
      summaryWidth: 0,
      coverFees: false,
      amountPresets: amountPresetsOneTime,
      stripeClientSecret: "",
      testMode: false,
      donation: null,
      submissionCount: 0,

      // summaryTop: 138
    };
    this.summaryRef = React.createRef();
  }

  componentDidMount() {
    let url;
    if (
      (window.location.href.indexOf("testing") !== -1 ||
        window.location.href.indexOf("localhost") !== -1) &&
      window.location.href.indexOf("livemode") === -1
    ) {
      this.setState({ testMode: true });

      url = "https://testing.api.kpfa.org/premiums?active=yes";
    } else {
      url = "https://api.kpfa.org/premiums?active=yes";
    }

    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ premiums: response.records });
        getCategoryList(response.records);
      });

    const getCategoryList = (premiums) => {
      const categoryList = [];
      premiums.forEach((item) => {
        const { category_name: categoryName, active } = item;
        if (categoryName && active && !categoryList.includes(categoryName.toLowerCase())) {
          categoryList.push(categoryName.toLowerCase());
        }
      });

      // window.addEventListener("scroll", this.handleScroll);

      this.setState({ categories: categoryList });
    };

    // this.setState({ premiums: PremiumSamples });
    // getCategoryList(PremiumSamples);

    const summaryWidth = this.summaryRef.current.clientWidth;
    this.setState({ summaryWidth });
  }

  amountChange = (event) => {
    // const amount = event.target.value;

    if (event.target.id === "other-amount") {
      if (event.type === "blur") {
        if (
          parseInt(event.target.value) >= donationLimits.min &&
          parseInt(event.target.value) <= donationLimits.max
        ) {
          this.setState(
            {
              amountSelection: parseInt(event.target.value),
              minWarning: false,
              maxWarning: false,
              otherAmount: parseInt(event.target.value),
              otherFocus: false,
            },
            () => {
              this.updateAmountBasedOnTotal();
            },
          );
        }
        if (parseInt(event.target.value) < donationLimits.min) {
          this.setState(
            {
              amountSelection: donationLimits.min,
              minWarning: true,
              maxWarning: false,
              otherAmount: donationLimits.min,
              otherFocus: false,
            },
            () => {
              this.updateAmountBasedOnTotal();
            },
          );
        }
        if (event.target.value === "") {
          this.setState(
            {
              // amountSelection: donationLimits.min,
              minWarning: false,
              maxWarning: false,
              // otherAmount: donationLimits.min,
              otherFocus: false,
            },
            () => {
              this.updateAmountBasedOnTotal();
            },
          );
        }
        if (parseInt(event.target.value) > donationLimits.max) {
          this.setState(
            {
              amountSelection: donationLimits.max,
              minWarning: false,
              maxWarning: true,
              otherAmount: donationLimits.max,
              otherFocus: false,
            },
            () => {
              this.updateAmountBasedOnTotal();
            },
          );
        }
      }
      if (event.target === document.activeElement) {
        this.setState(
          {
            otherAmount: parseInt(event.target.value) || "",
            otherFocus: true,
          },
          () => {
            this.updateAmountBasedOnTotal();
          },
        );
      }
    }

    if (event.target.parentElement.classList.contains("donation-buttons")) {
      if (parseInt(event.target.value) >= donationLimits.min) {
        this.setState(
          {
            amountSelection: parseInt(event.target.value),
            minWarning: false,
            maxWarning: false,
            otherAmount: "",
            otherFocus: false,
          },
          () => {
            this.updateAmountBasedOnTotal();
          },
        );
      }
    }
  };

  donationTypeChange = (event) => {
    this.setState({ donationType: event.target.value }, () => {
      this.updateAmountBasedOnTotal();
    });
    //swap out arrays of default price options depending on if the donation type is monthly or one time
    event.target.value === "Monthly"
      ? this.setState({ amountPresets: amountPresetsMonthly })
      : this.setState({ amountPresets: amountPresetsOneTime });
  };

  updateTotal = () => {
    const cart = [...this.state.cart];

    if (cart.length > 0) {
      const allPrices = cart.map((item) => {
        return parseInt(item.price);
      });
      const allMonthlyPrices = cart.map((item) => {
        return parseInt(item.cog);
      });
      const oneTimeTotal = allPrices.reduce((total, price) => total + price);
      const recurringTotal = allMonthlyPrices.reduce((total, price) => total + price);
      this.setState(
        {
          oneTimeTotal: oneTimeTotal,
          recurringTotal: recurringTotal,
        },
        () => {
          this.updateAmountBasedOnTotal();
        },
      );
    }
    if (cart.length === 0) {
      this.setState(
        {
          oneTimeTotal: 0,
          recurringTotal: 0,
          otherAmount: !this.state.amountPresets.includes(this.state.amountSelection)
            ? this.state.amountSelection
            : "",
        },
        () => {
          this.updateAmountBasedOnTotal();
        },
      );
    }
  };

  updateOtherBasedOnTotal = () => {
    if (this.state.amount > this.state.amountSelection && this.state.otherFocus === false) {
      if (this.state.donationType === donationTypes.oneTime) {
        this.setState({
          otherAmount: this.state.oneTimeTotal,
        });
      }
      if (this.state.donationType === donationTypes.monthly) {
        this.setState({
          otherAmount: this.state.recurringTotal,
        });
      }
    }
    if (
      this.state.oneTimeTotal <= this.state.amountSelection ||
      this.state.recurringTotal <= this.state.amountSelection
    ) {
      if (
        this.state.amountPresets.includes(this.state.amount) === true &&
        this.state.otherFocus === false
      ) {
        this.setState({
          otherAmount: "",
        });
      }
    }
  };

  updateAmountBasedOnTotal = () => {
    if (this.state.oneTimeTotal === 0 && this.state.recurringTotal === 0) {
      this.setState({
        amount: this.state.amountSelection,
        amountIncreaseWarning: false,
      });
    }
    if (this.state.donationType === donationTypes.oneTime) {
      if (this.state.oneTimeTotal > 0 && this.state.amountSelection < this.state.oneTimeTotal) {
        this.setState(
          {
            amount: this.state.oneTimeTotal,
            amountIncreaseWarning: true,
          },
          () => {
            this.updateOtherBasedOnTotal();
          },
        );
      }
      if (this.state.oneTimeTotal > 0 && this.state.amountSelection >= this.state.oneTimeTotal) {
        this.setState(
          {
            amount: this.state.amountSelection,
            amountIncreaseWarning: false,
          },
          () => {
            this.updateOtherBasedOnTotal();
          },
        );
      }
    }
    if (this.state.donationType === donationTypes.monthly) {
      if (this.state.recurringTotal > 0 && this.state.amountSelection < this.state.recurringTotal) {
        this.setState(
          {
            amount: this.state.recurringTotal,
            amountIncreaseWarning: true,
          },
          () => {
            this.updateOtherBasedOnTotal();
          },
        );
      }
      if (
        this.state.recurringTotal > 0 &&
        this.state.amountSelection >= this.state.recurringTotal
      ) {
        this.setState(
          {
            amount: this.state.amountSelection,
            amountIncreaseWarning: false,
          },
          () => {
            this.updateOtherBasedOnTotal();
          },
        );
      }
    }
  };

  addPremium = (event) => {
    const tempCart = [...this.state.cart];
    const tempCartIDs = [...this.state.cartIDs];
    const premiums = [...this.state.premiums];
    const cartDetails = [...this.state.cartDetails];

    const premiumId = Number(event.target.value);
    const variantId = Number(event.target.dataset.variant);
    const variantName = event.currentTarget.dataset.variantname;

    premiums.forEach((premium) => {
      if (premium.id === premiumId) {
        if (variantId) {
          tempCart.push(premium);
          tempCartIDs.push(variantId);
          cartDetails.push(variantName);
        } else {
          tempCart.push(premium);
          tempCartIDs.push(premiumId);
          cartDetails.push("");
        }
      }
    });

    this.setState(
      {
        cart: tempCart,
        cartIDs: tempCartIDs,
        cartDetails,
      },
      () => {
        this.updateTotal();
      },
    );
  };

  removePremium = (event) => {
    const tempCart = [...this.state.cart];
    // const premiumId = event.target.id;
    const cartIndex = event.target.dataset.index;
    const tempCartIDs = [...this.state.cartIDs];
    const cartDetails = [...this.state.cartDetails];

    tempCart.splice(cartIndex, 1);
    tempCartIDs.splice(cartIndex, 1);
    cartDetails.splice(cartIndex, 1);

    this.setState(
      {
        cart: tempCart,
        cartIDs: tempCartIDs,
        cartDetails,
      },
      () => {
        this.updateTotal();
      },
    );
  };

  updateStateFormData = (values) => {
    this.setState({
      formData: values,
      canSubmit: true,
      showModal: true,
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  // handleScroll = event => {
  //   const windowScrollY = event.currentTarget.scrollY;

  //   if (windowScrollY >= 0 && windowScrollY <= 120) {
  //     const summaryTop = 138 - windowScrollY;
  //     this.setState({ summaryTop });
  //   } else if (windowScrollY > 120) {
  //     this.setState({ summaryTop: 38 });
  //   }
  // };
  toggleCoverFees = (checked) => {
    this.setState({ coverFees: checked });
  };

  setClientSecret = (val) => {
    this.setState({ stripeClientSecret: val });
  };

  setDonation = (donation) => {
    this.setState({ donation });
  };

  incrementSubmissionCount = () => {
    this.setState((prevState) => ({
      submissionCount: prevState.submissionCount + 1,
    }));
  };

  /*
  coverFeesMultiplier = (donationAmount) => {
    return donationAmount//need to do the math here, this can be applied to the displayed number in the Summary as well as modifying the amount sent in SubmitModal POST request
  }
  */
  render() {
    return (
      <>
        <div className='container'>
          <PageHeader />
        </div>
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-md-9'>
              {this.state.testMode ? <h3 className='text-danger'>Test Mode</h3> : null}
              <Header />
              <OtherWaysToSupport />
              {/* <Featured /> */}
              <DonationType
                donationTypeChange={this.donationTypeChange}
                donationType={this.state.donationType}
              />
              <AmountSelector
                amountChange={this.amountChange}
                amount={this.state.amount}
                amountSelection={this.state.amountSelection}
                otherAmount={this.state.otherAmount}
                otherFocus={this.state.otherFocus}
                oneTimeTotal={this.state.oneTimeTotal}
                recurringTotal={this.state.recurringTotal}
                donationType={this.state.donationType}
                amountPresets={this.state.amountPresets}
              />
              <Premiums
                premiums={this.state.premiums}
                categories={this.state.categories}
                cartIDs={this.state.cartIDs}
                addPremium={this.addPremium}
                donationType={this.state.donationType}
              />
              <ContactForm
                updateStateFormData={this.updateStateFormData}
                formData={this.state.formData}
                amount={this.state.amount}
                donationType={this.state.donationType}
                toggleCoverFees={this.toggleCoverFees}
                stripeClientSecret={this.state.stripeClientSecret}
                setClientSecret={this.setClientSecret}
                setDonation={this.setDonation}
                testMode={this.state.testMode}
                incrementSubmissionCount={this.incrementSubmissionCount}
                premiums={this.state.premiums}
              />
              {/* <ContactInformation
            /> */}
              <SubmitCart
                donation={this.state.donation}
                setDonation={this.setDonation}
                formData={this.state.formData}
                amount={this.state.amount}
                donationType={this.state.donationType}
                cart={this.state.cart}
                cartIDs={this.state.cartIDs}
                cartDetails={this.state.cartDetails}
                canSubmit={this.state.canSubmit}
                oneTimeTotal={this.state.oneTimeTotal}
                recurringTotal={this.state.recurringTotal}
                showModal={this.state.showModal}
                hideModal={this.hideModal}
                setClientSecret={this.setClientSecret}
                stripeClientSecret={this.state.stripeClientSecret}
                testMode={this.state.testMode}
                submissionCount={this.state.submissionCount}
              />
            </div>
            <div className='col-md-3 inpage-summary' ref={this.summaryRef}>
              <Summary
                amount={this.state.amount}
                donationType={this.state.donationType}
                minWarning={this.state.minWarning}
                maxWarning={this.state.maxWarning}
                cart={this.state.cart}
                cartDetails={this.state.cartDetails}
                amountSelection={this.state.amountSelection}
                amountIncreaseWarning={this.state.amountIncreaseWarning}
                oneTimeTotal={this.state.oneTimeTotal}
                recurringTotal={this.state.recurringTotal}
                removePremium={this.removePremium}
                inPageSummary={true}
                summaryWidth={this.state.summaryWidth}
                // summaryTop={this.state.summaryTop}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default App;
