import SubmitModal from "./SubmitModal";

const SubmitCart = ({
  formData,
  amount,
  donationType,
  cart,
  cartIDs,
  canSubmit,
  oneTimeTotal,
  recurringTotal,
  showModal,
  hideModal,
  cartDetails,
  setClientSecret,
  stripeClientSecret,
  testMode,
  setDonation,
  donation,
  submissionCount,
}) => {
  if (canSubmit) {
    //adjust data before adding to PremiumsForm
    const formAmount = amount.toString();
    const premiumsForm = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      address1: formData.address1,
      address2: formData.address2 || "",
      city: formData.city,
      state: formData.state,
      country: formData.country,
      postal_code: formData.zip,
      phone: Number(formData.phone) || "",
      email: formData.email || "",
      shipping_firstname: formData.showShippingAddress
        ? formData.firstNameShipping
        : formData.firstName,
      shipping_lastname: formData.showShippingAddress
        ? formData.lastNameShipping
        : formData.lastName,
      shipping_address1: formData.showShippingAddress
        ? formData.address1Shipping
        : formData.address1,
      shipping_address2: formData.showShippingAddress
        ? formData.address2Shipping || ""
        : formData.address2,
      shipping_city: formData.showShippingAddress ? formData.cityShipping : formData.city,
      shipping_state: formData.showShippingAddress ? formData.stateShipping : formData.state,
      shipping_country: formData.showShippingAddress ? formData.countryShipping : formData.country,
      shipping_postal_code: formData.showShippingAddress ? formData.zipShipping : formData.zip,
      method: "stripe",
      type: donationType === "One-Time" ? "Pledge" : "Sustainer",
      amount: Number(formAmount),
      installment: donationType,
      comments: formData.comment,
      add_me: "", // Missing in form
      read_onair: formData.readOnair,
      paperless: formData.paperless,
      donotshare: "", // Missing in form
      browser: navigator.userAgent,
      show_name: "", // Missing in form
      source: "WebSite",
      donation_match: formData.donationMatch,
      premiums: cartIDs,
      premiums_cart: cart,
      tos_accept: true,
      accept_tos: formData.acceptTos,
    };

    return (
      <div>
        <SubmitModal
          donation={donation}
          setDonation={setDonation}
          amount={amount}
          donationType={donationType}
          cart={cart}
          oneTimeTotal={oneTimeTotal}
          recurringTotal={recurringTotal}
          premiumsForm={premiumsForm}
          showModal={showModal}
          hideModal={hideModal}
          formData={formData}
          cartDetails={cartDetails}
          setClientSecret={setClientSecret}
          stripeClientSecret={stripeClientSecret}
          submissionCount={submissionCount}
          testMode={testMode}
        />
      </div>
    );
  }
  return null;
};

export default SubmitCart;
