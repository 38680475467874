import * as Yup from "yup";
import { errorStrings } from "./validationStrings";

const supportFormSchema = Yup.object().shape({
  showShippingAddress: Yup.boolean(),
  firstName: Yup.string()
    .min(2, errorStrings.min)
    .max(50, errorStrings.max)
    .required(errorStrings.required),
  lastName: Yup.string()
    .min(2, errorStrings.min)
    .max(50, errorStrings.max)
    .required(errorStrings.required),
  address1: Yup.string()
    .min(5, errorStrings.min)
    .max(50, errorStrings.max)
    .required(errorStrings.required),
  address2: Yup.string().min(2, errorStrings.min).max(50, errorStrings.max),
  city: Yup.string()
    .min(2, errorStrings.min)
    .max(50, errorStrings.max)
    .required(errorStrings.required),
  state: Yup.string()
    .min(2, errorStrings.min)
    .max(50, errorStrings.max)
    .required(errorStrings.required),
  zip: Yup.string().when("country", {
    is: "US",
    then: Yup.string()
      .min(5, errorStrings.min)
      .max(9, errorStrings.max)
      .required(errorStrings.required),
    otherwise: Yup.string()
      .min(5, errorStrings.min)
      .max(20, errorStrings.max)
      .required(errorStrings.required),
  }),
  country: Yup.string()
    .min(2, errorStrings.min)
    .max(50, errorStrings.max)
    .required(errorStrings.required),
  phone: Yup.string().when("country", {
    is: "US",
    then: Yup.string()
      .min(10, errorStrings.min)
      .max(10, errorStrings.max)
      .required(errorStrings.required),
    otherwise: Yup.string()
      .min(10, errorStrings.min)
      .max(20, errorStrings.max)
      .required(errorStrings.required),
  }),
  email: Yup.string().email(errorStrings.email).required(errorStrings.required),
  firstNameShipping: Yup.string().when("showShippingAddress", {
    is: true,
    then: Yup.string()
      .min(2, errorStrings.min)
      .max(50, errorStrings.max)
      .required(errorStrings.required),
  }),
  lastNameShipping: Yup.string().when("showShippingAddress", {
    is: true,
    then: Yup.string()
      .min(2, errorStrings.min)
      .max(50, errorStrings.max)
      .required(errorStrings.required),
  }),
  address1Shipping: Yup.string().when("showShippingAddress", {
    is: true,
    then: Yup.string()
      .min(5, errorStrings.min)
      .max(50, errorStrings.max)
      .required(errorStrings.required),
  }),
  address2Shipping: Yup.string().when("showShippingAddress", {
    is: true,
    then: Yup.string().min(2, errorStrings.min).max(50, errorStrings.max),
  }),
  stateShipping: Yup.string().when("showShippingAddress", {
    is: true,
    then: Yup.string()
      .min(2, errorStrings.min)
      .max(50, errorStrings.max)
      .required(errorStrings.required),
  }),
  cityShipping: Yup.string().when("showShippingAddress", {
    is: true,
    then: Yup.string()
      .min(2, errorStrings.min)
      .max(50, errorStrings.max)
      .required(errorStrings.required),
  }),
  zipShipping: Yup.string().when(
    ["showShippingAddress", "countryShipping"],
    (showShippingAddress, countryShipping, schema) => {
      if (showShippingAddress && countryShipping === "US") {
        return schema
          .min(5, errorStrings.min)
          .max(9, errorStrings.max)
          .required(errorStrings.required);
      }
      if (showShippingAddress && countryShipping !== "US") {
        return schema
          .min(5, errorStrings.min)
          .max(15, errorStrings.max)
          .required(errorStrings.required);
      }
    },
  ),
  countryShipping: Yup.string().when("showShippingAddress", {
    is: true,
    then: Yup.string()
      .min(2, errorStrings.min)
      .max(50, errorStrings.max)
      .required(errorStrings.required),
  }),
  comment: Yup.string().min(8, errorStrings.min).max(500, errorStrings.commentMax),
});

export default supportFormSchema;
