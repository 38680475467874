import { Component } from "react";
import PremiumCard from "./Premiums/PremiumCard";

class PremiumsByCategory extends Component {
  generatePremiums = () => {
    const premiums = this.props.premiums;
    const category = this.props.activeTab;

    const premiumsCards = premiums.map((premium) => {
      if (
        premium.active === true &&
        premium.category_name &&
        parseInt(premium.qty) > 0 &&
        (premium.category_name.toLowerCase() === category ||
          (category === "featured" && premium.featured === true))
      ) {
        return (
          <PremiumCard
            premium={premium}
            donationType={this.props.donationType}
            addPremium={this.props.addPremium}
            key={premium.id}
            cartIDs={this.props.cartIDs}
          />
        );
      }
      return null;
    });
    return premiumsCards;
  };

  render() {
    return <div className='row'>{this.generatePremiums(this.props)}</div>;
  }
}

export default PremiumsByCategory;
