import { Component } from "react";
import ShowMore from "./ShowMore";
import VariationsDropdown from "./VariationsDropdown";
import { donationTypes } from "../../constants";

class PremiumCard extends Component {
  constructor(props) {
    super(props);
    this.state = { variantId: "", variantName: "", disabled: false };
  }

  limitChecker = () => {};

  updateId = (event) => {
    const selectedId = event.target.value;
    const selectedName = event.currentTarget.selectedOptions[0].dataset.name;

    this.setState({ variantId: selectedId, variantName: selectedName, disabled: false });
  };

  addPremiumWrapper = (event) => {
    //limit checker for variants and standard premiums to prevent adding more to cart then are in stock
    //todo: add a UI notification so donors have more clarity about why they cannot add more
    if (this.state.variantId.length) {
      if (
        this.props.cartIDs.reduce(
          (acc, cur) => (cur === Number(this.state.variantId) ? ++acc : acc),
          0,
        ) >=
        this.props.premium.variants.variations.filter(
          (variant) => variant.id === Number(this.state.variantId),
        )[0]?.qty
      ) {
        this.setState({ disabled: true });
      } else {
        this.props.addPremium(event);
      }
    } else if (
      this.props.cartIDs.reduce((acc, cur) => (cur === this.props.premium.id ? ++acc : acc), 0) >=
      this.props.premium.qty
    ) {
      this.setState({ disabled: true });
    } else {
      this.props.addPremium(event);
    }
  };

  render() {
    const { name, id, price, description, img_url: imgURL, cog } = this.props.premium;
    return (
      <div className='col-sm-6 col-md-6 col-lg-4' key={id}>
        <div className='card'>
          <div className='card-img-top'>
            <img className='card-img-center' src={imgURL} alt={name} />
          </div>
          <div className='card-body'>
            <h5 className='card-title'>{name}</h5>
            <h4 className='card-subtitle mb-2 '>
              ${this.props.donationType === donationTypes.oneTime ? price : cog + "/month"}
            </h4>
            {this.props.premium.variants ? (
              <VariationsDropdown premium={this.props.premium} updateId={this.updateId} />
            ) : null}

            <ShowMore description={description} />
          </div>
          <div className='card-footer'>
            {this.props.premium.variants && this.state.variantId === "" ? (
              <button className='btn btn-secondary btn-block select-premium disabled' value={id}>
                Select {this.props.premium.variants.name} to Add
              </button>
            ) : (
              <button
                className={`btn btn-primary btn-block select-premium`}
                disabled={this.state.disabled}
                value={id}
                data-variant={this.props.premium.variants ? this.state.variantId : ""}
                data-variantname={this.props.premium.variants ? this.state.variantName : ""}
                onClick={(event) => this.addPremiumWrapper(event)}
              >
                Add
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PremiumCard;
