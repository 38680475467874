import { Component } from "react";
import commaNumber from "comma-number";
import { donationTypes, donationLimits } from "../constants";

class Summary extends Component {
  constructor() {
    super();
    this.state = { expanded: false };
  }
  displayCartItems = (cart) => {
    const CartItems = cart.map((item, index) => {
      return (
        <li
          key={index}
          className='list-group-item d-flex justify-content-between align-items-center'
        >
          {item.name}
          {this.props.cartDetails[index] !== "" ? (
            <span className='font-italic'>({this.props.cartDetails[index]})</span>
          ) : null}
          {this.props.inPageSummary ? (
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm ml-2'
              aria-label='Close'
              // id={item.id}
              data-index={index}
              onClick={this.props.removePremium}
            >
              remove
            </button>
          ) : null}
        </li>
      );
    });
    return (
      <ul className='list-group w-100'>
        {cart.length > 0 ? (
          <li className='list-group-item list-group-item-primary'>
            <h5>
              <span className='badge badge-primary'>{this.props.cart.length}</span>
              &nbsp;&nbsp;Pledge Gifts Selected
            </h5>
          </li>
        ) : null}
        {CartItems}
        {cart.length > 0 ? (
          <li className='list-group-item list-group-item-dark d-flex justify-content-between align-items-center'>
            Pledge Gift(s) Total:
            <strong>
              $
              {this.props.donationType === donationTypes.oneTime
                ? this.props.oneTimeTotal
                : this.props.recurringTotal}
              {this.props.donationType === donationTypes.monthly ? "/Month" : null}
            </strong>
          </li>
        ) : null}
      </ul>
    );
  };

  expand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <div
        className={this.state.expanded ? "mt-3 mx-auto expanded" : "mt-3"}
        id='summary'
        style={
          this.props.inPageSummary
            ? {
                position: "fixed",
                //width: this.props.summaryWidth + "px",
                width: "auto",
                // top: this.props.summaryTop + "px",
                // transition: "top 0.1s ease-in-out"
              }
            : { width: "100%" }
        }
      >
        <div id='summary-text'>
          {this.props.inPageSummary ? (
            <h4 className='mb-3 text-uppercase text-center'>Donation Summary</h4>
          ) : null}
          <h1 className='text-center text-primary'>
            ${commaNumber(this.props.amount)}
            {this.props.donationType === "Monthly"
              ? !this.props.inPageSummary
                ? "/month"
                : "/mo"
              : null}
          </h1>
          {this.props.donationType === "Monthly" && !this.props.inPageSummary ? (
            <p class='text-center'>Your donation continues indefinitely until canceled</p>
          ) : null}
          {this.props.donationType === "One-Time" ? (
            <h5 className='text-center'>{this.props.donationType}</h5>
          ) : (
            ""
          )}
        </div>
        {this.props.inPageSummary && this.props.cart.length > 0 ? (
          <div id='summary-expand' className='w-100 text-center mb-1'>
            <button type='button' className='btn btn-link text-center' onClick={this.expand}>
              {this.state.expanded ? "Hide Details" : "Expand Details"}
            </button>
          </div>
        ) : null}
        {this.props.minWarning ? (
          <h6 className='text-center'>Minimum donation is ${donationLimits.min}</h6>
        ) : null}
        {this.props.maxWarning ? (
          <h6 className='text-center'>Maximum donation is ${commaNumber(donationLimits.max)}</h6>
        ) : null}
        {this.displayCartItems(this.props.cart)}
        {this.props.amountIncreaseWarning ? (
          <h6 className='text-center text-danger mt-3'>
            Please note: Your donation amount has increased to match the total for your selected
            gifts.
          </h6>
        ) : null}
        {this.props.inPageSummary ? null : (
          <div className='row mt-3 mb-2'>
            <div
              className={
                this.props.formData.showShippingAddress === true
                  ? "col-sm-5 mx-auto"
                  : "col-sm-12 mx-auto"
              }
            >
              <h5>
                {this.props.formData.showShippingAddress === true
                  ? "Billing Address"
                  : "Billing & Shipping Address"}
              </h5>
              <strong>
                {this.props.formData.firstName} {this.props.formData.lastName}
              </strong>
              <p>
                {this.props.formData.address1} <br />
                {this.props.formData.address2 ? <p>{this.props.formData.address2}</p> : null}
                {this.props.formData.city}, {this.props.formData.state} {this.props.formData.zip}{" "}
                <br />
                {this.props.formData.country}
              </p>
              <p>
                {this.props.formData.phone ? "Phone: " + this.props.formData.phone : null}
                <br />
                {this.props.formData.email ? "Email: " + this.props.formData.email : null}
              </p>
            </div>
            {this.props.formData.showShippingAddress === true ? (
              <div className='col-sm-5 mx-auto'>
                <h5>Shipping Address</h5>
                <p>
                  <strong>
                    {this.props.formData.firstNameShipping} {this.props.formData.lastNameShipping}
                  </strong>
                </p>
                <p>
                  {this.props.formData.address1Shipping} <br />
                  {this.props.formData.address2Shipping
                    ? this.props.formData.address2Shipping
                    : null}
                  <br />
                  {this.props.formData.cityShipping}, {this.props.formData.stateShipping}{" "}
                  {this.props.formData.zipShipping} <br />
                  {this.props.formData.countryShipping}
                </p>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Summary;
