import React from "react";
import kpfaLogo from "../images/70bannermic.png";

const PageHeader = (props) => {
  return (
    <div className='row mb-4'>
      <div
        id='header-bg'
        style={{
          width: "100%",
          position: "absolute",
          top: "0px",
          left: "0px",
          height: "120px",
          boxShadow: "0px 6px 23px 0px rgba(0,0,0,0.1)",
        }}
        className='bg-white'
      >
        &nbsp;
      </div>
      <div className='col-sm-8  p-3'>
        <a href='https://kpfa.org'>
          <img style={{ width: "300px" }} src={kpfaLogo} alt='70th Anniversary KPFA Logo' />
        </a>
      </div>
    </div>
  );
};

export default PageHeader;
