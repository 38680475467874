import React from "react";
import Mask from "react-text-mask";

const Input = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  hidden,
  ...props
}) => {
  // console.log("touched", touched);
  const inputClassNme = () => {
    if (touched[field.name]) {
      if (errors[field.name]) {
        return "form-control is-invalid";
      }
      if (!errors[field.name]) {
        return "form-control is-valid";
      }
    }
    return "form-control";
  };

  const inputType = () => {
    if (props.mask) {
      return (
        <Mask
          // {...field}
          {...props}
          className={inputClassNme()}
          type={props.type || "text"}
          placeholder={props.placeholder}
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          style={hidden ? { display: "none" } : {}}
        />
      );
    }
    return (
      <input
        {...field}
        {...props}
        // className="form-control"
        className={hidden ? "d-none" : inputClassNme()}
        type={props.type || "text"}
        placeholder={props.placeholder}
        // required={props.required || false}
      />
    );
  };

  return (
    <>
      {/* {console.log("errors", errors, "field", field)} */}

      <label htmlFor={props.id} className={hidden ? "d-none" : ""}>
        {props.label}
      </label>
      {inputType()}
      <div className='invalid-feedback'>{errors[field.name] || null}</div>
    </>
  );
};

export default Input;
