import React from "react";

const Checkbox = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className='form-group'>
      <div className='form-check col-sm-12'>
        <label className='form-check-label' htmlFor={props.id}>
          <input
            {...field}
            {...props}
            className='form-check-input'
            type='checkbox'
            id={props.id}
            required={props.required || false}
          />
          {props.label}
          <div className='invalid-feedback'>{errors[field.name] || null}</div>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
