import { Component } from "react";
import PremiumsTabContent from "./PremiumsTabContent";
import "../styles/premiums.css";

class Premiums extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "featured",
    };
  }

  handleCategoryClick = (event) => {
    event.preventDefault();
    // console.log(event.target.id);
    this.setState({ activeTab: event.target.id });
  };

  renderNavItems = (categories) => {
    const catagoriesPlusFeatured = [...categories];
    catagoriesPlusFeatured.splice(0, 0, "featured");
    let navItems;
    navItems = catagoriesPlusFeatured.map((category, index) => {
      // premiums[category] = ``;
      return (navItems = (
        <li className='nav-item' key={category}>
          <a
            className={
              category === this.state.activeTab ||
              (category === categories[0] && this.state.activeTab === "")
                ? "nav-link active"
                : "nav-link"
            }
            // id={category + "-tab"}
            // data-toggle="tab"
            href={category}
            role='tab'
            // aria-controls={category}
            // aria-selected="true"
            onClick={this.handleCategoryClick}
            id={category}
          >
            {`${category}`.charAt(0).toUpperCase() + `${category}`.slice(1)}
          </a>
        </li>
      ));
    });
    return navItems;
  };

  render() {
    return (
      <div className='mb-3 mt-3'>
        <h3 className='mb-3 text-uppercase'>Pledge Gifts</h3>
        <ul className='nav nav-pills nav-fill' role='tablist'>
          {this.renderNavItems(this.props.categories)}
        </ul>
        <div className='tab-content' id='premiums-container'>
          <PremiumsTabContent
            premiums={this.props.premiums}
            categories={this.props.categories}
            activeTab={this.state.activeTab}
            addPremium={this.props.addPremium}
            donationType={this.props.donationType}
            cartIDs={this.props.cartIDs}
          />
        </div>
      </div>
    );
  }
}

export default Premiums;
