import React, { Component } from "react";

class VariationsDropdown extends Component {
  render() {
    return (
      <div className='form-group row'>
        <label className='col-md-4 col-form-label'>{this.props.premium.variants.name}</label>
        <div className='col-md-8'>
          <select
            className='form-control'
            id='exampleFormControlSelect1'
            onChange={this.props.updateId}
          >
            <option value='' data-name='' key='select'>
              - Select {this.props.premium.variants.name} -
            </option>
            {this.props.premium.variants.variations
              .filter((variation) => variation.qty > 0)
              .map((variation) => {
                return (
                  <option value={variation.id} data-name={variation.name} key={variation.id}>
                    {variation.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    );
  }
}

export default VariationsDropdown;
