import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import topbar from "topbar";
import Summary from "./Summary";
import checkmark from "../images/checkmark.svg";
import "../styles/submit-modal.css";

// importing Stripe Elements for Credit Card processing via tokens
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import PaymentForm from "./PaymentForm";

topbar.config({ barThickness: 10 });

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.

let stripeKey;
//use stripe test publishable key when in testMode
(window.location.href.indexOf("testing") !== -1 ||
  window.location.href.indexOf("localhost") !== -1) &&
window.location.href.indexOf("livemode") === -1
  ? (stripeKey = "pk_test_rnm58ULbGeHkmqKf3ghNkt1O")
  : (stripeKey = "pk_live_ez7cwJSBRZxvE0SjnPnAHizo");
const promise = loadStripe(stripeKey);

class SubmitModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: true,
      processingModal: false,
      failureModal: false,
      successModal: false,
      response: null,
      responseContent: null,
      receipt: null,
      submitted: false,
      retryPayment: 0,
      stripeError: false,
      stripeSucceeded: false,
      stripeProcessing: false,
    };
  }

  setSucceeded = (val) => {
    this.setState({ stripeSucceeded: val });
  };

  setError = (val) => {
    this.setState({ stripeError: val });
  };

  setProcessing = (val) => {
    this.setState({ stripeProcessing: val });
  };

  returnToHome = () => {
    window.location.replace("https://kpfa.org");
  };

  resetModals = () => {
    this.setState({
      confirmationModal: false,
      processingModal: false,
      failureModal: false,
      successModal: false,
      response: null,
      responseContent: null,
      receipt: null,
      submitted: false,
    });
    this.props.hideModal();
  };

  setFailureFromCheckoutForm = (responseContent) => {
    this.setState({
      response: "error",
      responseContent: responseContent,
      submitted: true,
      processingModal: false,
      failureModal: true,
    });
  };

  url = `https://${this.props.testMode ? "testing." : ""}api.kpfa.org/donations`;

  createDonation = async () => {
    //this.props.setProcessing(true);

    topbar.show();

    try {
      //KPFA API Create Donation POST request
      let response = await fetch(this.url, {
        method: "POST",
        body: JSON.stringify(this.props.premiumsForm),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        response.json().then((response) => {
          this.props.setDonation(response);
          //here, we take the client secret returned from the KPFA API, which creates the PaymentIntent. Once the client has that, we set the client secret to be used by the Stripe card element.
          setTimeout(() => {
            //1 second timeout to give Stripe time to send payment success to KPFA API via webhook
            this.props.setClientSecret(response.client_secret);
            this.setState({
              response: "200",
              responseContent: `Your Donation # is ${response.id}`,
              receipt: `https://${this.props.testMode ? "testing." : ""}api.kpfa.org/receipt/${
                response.transaction_id
              }`,
              submitted: true,
            });
            topbar.hide();

            return response.client_secret;
          }, 2500);

          //here, I need to trigger the processing modal but only after values have been set by our boy elements over in CardCheckoutForm. ugh.
        });
      } else {
        response.json().then((response) => {
          this.setState({
            response: response.status,
            responseContent: response.message,
            submitted: true,
            processingModal: false,
            failureModal: true,
          });
          topbar.hide();
        });
      }
      //if (response.ok && response.client_secret) {
    } catch (e) {
      this.setState({
        response: e,
        responseContent: null,
        submitted: true,
        processingModal: false,
        failureModal: true,
      });
      topbar.hide();
    }
  };

  progressToProcessingModal = () => {
    this.setState({
      confirmationModal: false,
      processingModal: true,
    });
    //this.props.hideModal();
  };

  progressToSuccessModal = () => {
    this.setState({
      successModal: true,
      processingModal: false,
    });
  };

  returnToPayment = () => {
    this.setState({
      failureModal: false,
      confirmationModal: true,
      processingModal: false,
      successModal: false,
      response: null,
      responseContent: null,
      submitted: false,
    });
  };

  initiateDonation = () => {
    /*
     * create the donation, then the client secret, then make the payment, then display notices to the user and show confirmation modal
     * this is currently handled in this flow:
     * 1. createDonation is called, which gets the clientSecret from KPFA API.
     * 2. useEffect in CardCheckoutForm responds to clientSecret being set by submitting the request to Stripe to confirm payment.
     * 3. componentDidUpdate in the SubmitModal responds to stripeSucceeded or stripeError state change and displays appropriate modal
     */
    if (!this.props.donation) {
      this.createDonation();
    } else {
      //this is not the best way to handle triggering confirmCardPayment but it was the fastest to code!
      this.setState({ retryPayment: this.state.retryPayment + 1 });
    }
  };

  componentDidMount() {
    this.initiateDonation();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.submissionCount !== prevProps.submissionCount &&
      this.props.submissionCount > 1
    ) {
      this.createDonation();
    }
  }

  confirmationModal = () => {
    if (this.props.showModal === true) {
      return (
        <Modal
          isOpen={this.props.showModal}
          toggle={this.resetModals}
          className={this.props.className}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <ModalHeader toggle={this.resetModals}>
            Please review and confirm your donation
          </ModalHeader>
          <ModalBody>
            <Summary
              amount={this.props.amount}
              donationType={this.props.donationType}
              cart={this.props.cart}
              oneTimeTotal={this.props.oneTimeTotal}
              recurringTotal={this.props.recurringTotal}
              formData={this.props.formData}
              cartDetails={this.props.cartDetails}
            />
            {this.props.stripeClientSecret.length > 0 ? (
              <Elements
                stripe={promise}
                options={{
                  fonts: [{ cssSrc: "https://use.typekit.net/kng8mbk.css" }],
                  mode: "payment",
                  currency: "usd",
                  amount: this.props.amount * 100,
                  setup_future_usage: "off_session",
                  ...(this.props.donationType === "Monthly" && {
                    paymentMethodTypes: ["card", "us_bank_account"],
                    paymentMethodCreation: "manual",
                  }),
                }}
              >
                <PaymentForm
                  setError={this.setError}
                  setSucceeded={this.setSucceeded}
                  setProcessing={this.setProcessing}
                  resetModals={this.resetModals}
                  setFailureFromCheckoutForm={this.setFailureFromCheckoutForm}
                  retryPayment={this.state.retryPayment}
                  confirmDonation={this.confirmDonation}
                  amount={this.props.amount}
                  createDonation={this.createDonation}
                  clientSecret={this.props.stripeClientSecret}
                  setClientSecret={this.props.setClientSecret}
                  hideModal={this.props.hideModal}
                  progressToProcessingModal={this.progressToProcessingModal}
                  progressToSuccessModal={this.progressToSuccessModal}
                  formData={this.props.formData}
                  donationType={this.props.donationType}
                />
              </Elements>
            ) : null}
          </ModalBody>
        </Modal>
      );
    }
  };

  processingModal = () => {
    if (this.state.processingModal === true) {
      return (
        <Modal
          isOpen={this.state.processingModal}
          className={this.props.className}
          backdrop='static'
          keyboard={false}
        >
          <ModalHeader>
            <span className='badge badge-warning'>Please Wait</span> Processing Your Donation
          </ModalHeader>
          <ModalBody>
            <div className='d-flex justify-content-center mt-2 mb-2'>
              <h3 className='text-uppercase'>
                <div
                  className='spinner-border text-primary'
                  style={{
                    width: "2rem",
                    height: "2rem",
                    marginRight: "0.5rem",
                  }}
                  role='status'
                >
                  <span className='sr-only'>Processing Donation...</span>
                </div>
                Processing Donation...
              </h3>
            </div>
            <p>
              Please do not close this window or navigate away from this page until your donation is
              processed and the confirmation message is displayed.
            </p>
          </ModalBody>
        </Modal>
      );
    }
    return null;
  };

  failureModal = () => {
    if (this.state.failureModal === true) {
      return (
        <Modal
          isOpen={this.state.failureModal}
          toggle={this.resetModals}
          className={this.props.className}
          backdrop={true}
          keyboard={true}
        >
          <ModalHeader toggle={this.resetModals}>
            <span className='badge badge-danger'>Error</span> Donation Failed
          </ModalHeader>
          <ModalBody>
            <p>Your donation to KPFA did not process successfully.</p>
            {this.state.responseContent ? (
              <>
                <p>
                  <strong>{this.state.responseContent}</strong>
                </p>
                <p>Please make any required changes to the donation form and try again.</p>
              </>
            ) : null}
            {!this.state.responseContent ? (
              <p>
                Error reaching KPFA server. Please check your internet connection and try again.
              </p>
            ) : null}
          </ModalBody>
          <ModalFooter>
            {this.state.stripeError ? (
              <Button color='warning' onClick={this.returnToPayment}>
                Return to Payment Form
              </Button>
            ) : (
              <Button color='primary' onClick={this.resetModals}>
                Return to donation form
              </Button>
            )}
          </ModalFooter>
        </Modal>
      );
    }
    return null;
  };

  successModal = () => {
    if (this.state.successModal === true) {
      return (
        <Modal
          isOpen={this.state.successModal}
          toggle={this.returnToHome}
          className={this.props.className}
          backdrop={true}
          keyboard={true}
        >
          <ModalHeader toggle={this.returnToHome}>
            <span className='badge badge-success'>Success</span> Thank You for Supporting KPFA!
          </ModalHeader>
          <ModalBody>
            <div className='checkmark-container'>
              <img src={checkmark} className='fadeIn' alt='checkmark' />
              <p>
                Your donation to KPFA was processed successfully. Please allow 6 to 8 weeks for any
                gift items to be shipped to you.
              </p>
              <p>
                <strong>{this.state.responseContent}</strong>
              </p>
              <a href={this.state.receipt}>View Receipt</a>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.returnToHome}>
              Return to KPFA Homepage
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        {this.confirmationModal()}
        {this.processingModal()}
        {this.failureModal()}
        {this.successModal()}
      </>
    );
  }
}

export default SubmitModal;
