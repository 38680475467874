import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FormData } from "../types";

type Props = {
  setFailureFromCheckoutForm: (responseContent: string) => void;
  setError: (value: boolean) => void;
  setProcessing: (value: boolean) => void;
  retryPayment: number;
  setSucceeded: (value: boolean) => void;
  progressToProcessingModal: () => void;
  resetModals: () => void;
  formData: FormData;
  progressToSuccessModal: () => void;
  clientSecret: string;
  donationType: "One-Time" | "Monthly";
};

const PaymentForm = ({
  setFailureFromCheckoutForm,
  setError,
  setProcessing,
  retryPayment,
  setSucceeded,
  progressToProcessingModal,
  progressToSuccessModal,
  resetModals,
  formData,
  clientSecret,
  donationType,
}: Props) => {
  const [stripeComplete, setStripeComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [mandate, setMandate] = useState("");

  useEffect(() => {
    if (elements) {
      elements.getElement("payment")?.on("change", (event) => {
        if (event.complete) {
          setStripeComplete(true);
        }
        if (event.value.type === "us_bank_account") {
          if (donationType === "One-Time") {
            setMandate(
              "By clicking 'Confirm Donation', you authorize KPFA Radio to debit the bank account specified above for any amount owed for charges arising from your charitable donation to KPFA Radio, including the acceptance of gifts or services associated with your donation to KPFA Radio, pursuant to KPFA’s website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to KPFA Radio within 30 (thirty) days' notice.",
            );
          } else {
            setMandate(
              "By clicking 'Confirm Donation', you authorize KPFA Radio to debit the bank account specified above for any amount owed for charges arising from your charitable donation to KPFA Radio, including the acceptance of gifts or services associated with your donation to KPFA Radio, pursuant to KPFA’s website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to KPFA Radio within 30 (thirty) days' notice. If you have chosen to utilize KPFA Radio’s service to make recurring donations to the station, please note that you authorize KPFA Radio to debit your bank account periodically as per your agreement. Payments that fall outside of the regular and ongoing debits authorized above will only be debited after your authorization is obtained.",
            );
          }
        } else {
          setMandate("");
        }
      });
    }
  }, [elements, donationType]);

  const handleSubmit = async (event: React.FormEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    progressToProcessingModal();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(true);
      setProcessing(false);
      setFailureFromCheckoutForm("Payment Failed: Submit Error");
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        //todo: add routing for payment methods that need a return url
        return_url: "https://kpfa.org",
        payment_method_data: {
          billing_details: {
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
            phone: formData.phone,
            address: {
              postal_code: formData.zip,
              country: formData.country,
              line1: formData.address1,
              line2: formData.address2,
              city: formData.city,
              state: formData.state,
            },
          },
        },
      },
      redirect: "if_required",
    });

    if (result.error) {
      setError(true);
      setProcessing(false);

      if (typeof result.error.message === "string") {
        setFailureFromCheckoutForm(result.error.message);
      } else {
        setFailureFromCheckoutForm("Payment Failed");
      }

      if (retryPayment > 0) {
        //if we are trying for a second+ time, we need to handle some of the modal flow from this function, as we're not relying on the createDonation function as usual
        if (result.error.message) {
          setFailureFromCheckoutForm(result.error.message);
        }
      }
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setError(false);
      setProcessing(false);
      setSucceeded(true);
      progressToSuccessModal();
    }
    //todo: force hard refresh after successful payment
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        className='mb-4'
        options={{
          fields: { billingDetails: "never" },
          terms: {
            card: "always",
            usBankAccount: "always",
          },
        }}
      />
      {mandate.length ? <p className='small alert alert-secondary mb-5'>{mandate}</p> : null}
      <Button className='mr-3' color='primary' disabled={!stripe || !stripeComplete}>
        Confirm Donation
      </Button>
      <Button color='secondary' onClick={resetModals}>
        Make a Change
      </Button>
    </form>
  );
};

export default PaymentForm;
