import { useState } from "react";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OtherWaysToSupport = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className='d-flex'>
        <Button color='primary' onClick={toggle} style={{ marginBottom: "1rem" }}>
          Other Ways To Donate
        </Button>
      </div>
      <div>
        <Collapse isOpen={isOpen} className='mb-4'>
          <Card>
            <div className='row p-3'>
              <div className='col-sm-12'>
                <p>
                  For information on other ways to donate, please contact our{" "}
                  <a href='mailto:development@kpfa.org?subject=Other ways to donate'>
                    development team
                  </a>
                </p>
              </div>
              <div className='col-sm-6 col-12 mb-2'>
                <Card className='h-100'>
                  <CardBody>
                    <div className='d-flex flex-column h-100 align-items-center'>
                      <a
                        href='https://kpfa.org/donate-your-vehicle'
                        className='d-flex justify-content-center flex-column h-100 text-dark'
                      >
                        <h4 className='text-black'>Donate your vehicle</h4>
                        <FontAwesomeIcon className='fa-6x mx-auto mt-auto' icon='car-side' />
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Card>
        </Collapse>
      </div>
    </>
  );
};

export default OtherWaysToSupport;
